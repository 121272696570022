.text-editor {
  width: auto;
}
.ql-editor {
  min-height: 100px;
}

.ql-editor img {
  height: auto;
  width: 50%;
}
.ql-editor video {
  height: auto;
  width: 50%;
}

.ql-formats-line {
  background: #ebecf0;
  width: 1px;
  height: 24px;
  display: inline-block;
  margin: 0 0px 10px -10px;
  user-select: none;
}
