@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scrollbar-width: none; /* Firefox */
}

html {
  scroll-behavior: smooth;
}

html, body, #root {
  height: 100%;
}

/* Typography */
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}

.fw-600 {
  font-weight: 600;
}

/* TODO: not working on firefox --> needs to fix */
/* Form Controls */
/* for removing wheels from input number field */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* Hide the spinner buttons on Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Hide the spinner buttons on Chrome, Safari, Edge, IE and Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  resize: none;
}

/* Variables */

:root {
  --background-color: white;
  --text-color: #121212;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}
