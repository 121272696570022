.dashboard-card-icon svg {
  height: 150px;
  width: 150px;
  opacity: 0.3;
  color: #bb86fc;
  margin-right: 15px;
}

/* For small screens and below */
@media only screen and (max-width: 600px) {
  .dashboard-card-icon svg {
    height: 100px;
    width: 100px;
    opacity: 0.5;
  }

  [class*="MuiBox-root"] {
    font-size: 20px;
  }
}

/* For extra-small screens and below */
@media only screen and (max-width: 400px) {
  .dashboard-card-icon svg {
    height: 75px;
    width: 75px;
    margin-right: 0;
  }
}
