.notes-page {
  min-height: calc(100vh - 50px);
  height: 100%;
  color: white;
  background-color: #121212;
}

.notes-content,
.notes-title {
  border: none;
  width: 100%;
}

.notes-title {
  letter-spacing: 0.00625em;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding-bottom: 12px;
  background: white;
  color: #121212;
}

.notes-content {
  padding-top: 20px;
  letter-spacing: 0.01428571em;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  background: white;
  color: #121212;
}

.notes-content::placeholder,
.notes-title::placeholder {
  color: gray;
  letter-spacing: 0.01785714em;
  font-weight: 500;
  line-height: 1.25rem;
}

.notes-content:active,
.notes-content:focus-visible,
.notes-content:focus,
.notes-title:active,
.notes-title:focus-visible,
.notes-title:focus {
  outline: none;
}

.cards-for-note-section {
  padding-top: 3%;
  display: grid;
  gap: 15px 50px;
  grid-template-columns: auto auto auto auto;
}
