.patient-panel-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  background-color: #121212;
  color: #e1e1e1;
}
.patient-top-section {
  display: flex;
  gap: 0px;
  flex: 0 0 auto;
}

.pt-left-section {
  flex: 2.5;
  border: 1.5px solid #e1e1e1;
}

.pt-center-section {
  flex: 2.5;
  border: 1.5px solid #e1e1e1;
}

.pt-right-section {
  flex: 1;
  border: 1.5px solid #e1e1e1;
  /* max-width: 10%; Adjusted width for Visit History */
}

.patient-middle-section {
  flex: 0 0 auto;
  border: 1.5px solid #e1e1e1;
  border-top: 0;
  border-bottom: 0;
}

.patient-bottom-section {
  flex: 1 0 auto;
  border: 1.5px solid #e1e1e1;
}

.p-heading {
  width: 100%;
  height: 25px;
  font-size: 15px;
  background: #2d2d2d;
  font-weight: 600;
  color: #e0e0e0;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 0.7px;
  /* border-bottom: 1px solid white; */
}

.patient-input {
  width: 100%;
}

@media only screen and (max-width: 1350px) {
  .p-heading {
    font-size: 11px;
  }
}
