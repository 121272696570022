.sidebar-username {
  font-size: 25px;
  padding: 20px 0 0 0;
  color: #fefefe;
}

.sidebar-organisation-name {
  font-size: 18px;
  color: #4cceac;
}

.css-1wvake5 {
  border-color: rgb(30, 30, 30) !important;
}

