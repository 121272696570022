footer {
  bottom: 0px;
  background-color: var(--footer-background);
  min-width: 300px;
  bottom: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer-wrapper {
  display: flex;
  background: var(--footer-background);
  flex-direction: column;
  /* max-width: 1320px; */
  width: 95%;
  padding: 16px;
}

/* top section */
.footer-top {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: start;
  padding: 16px 0px;
  justify-content: space-between;
}

.footer-line {
  display: block;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, #023275, #ccdfec 50%, #0c356a);
}

/* footer subscribe */
.footer-subscribe {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.footer-subscribe > input {
  border: 1px solid #e1e1e1;
  color: #111420;
  min-height: 36px;
  font-size: 1.2rem;
  flex: 1 0 120px;
  padding: 8px 12px;
  border-radius: 8px;
}

.footer-subscribe > button {
  cursor: pointer;
  background-color: #111420;
  border-radius: 8px;
  color: white;
  font-size: 1.25rem;
  min-width: 120px;
  min-height: 36px;
  flex: 1 0 80px;
  white-space: nowrap;
  padding: 8px 12px;
  border: 0px;
  outline: none;
}

/* footer columns */
.footer-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  padding: 24px 8px 16px 8px;
  margin: 0 auto;
  justify-content: space-between;
}

.footer-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 180px;
}

.footer-about-company {
  width: 50%;
}

.footer-logo-text {
  color: #0c356a;
  font-weight: 600;
  font-size: 2rem;
}

.footer-about {
  font-size: 25px;
  font-weight: 600;
}

/* Footer bottom */

.footer-bottom {
  text-align: center;
  border-top: 1px solid #e1e1e1;
  margin-top: 48px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding: 16px 0px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-bottom > small {
  font-size: 16px;
  margin: 0 4px;
}

.footer-headline > h2 {
  margin: 0px;
}

.footer-headline > p {
  margin: 12px 0px;
}

/* socials */

.social-links {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.social-links img {
  width: 24px;
  height: 24px;
  transition: all 0.2s ease-in-out;
}

.social-links img:hover {
  transform: scale(1.1);
}

/* mobile */

@media (max-width: 800px) {
  .footer-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px 8px 32px 8px;
  }
  .footer-bottom {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin: 0 auto;
  }
  .footer-columns {
    flex-direction: column;
    gap: 1rem;
    padding-top: 0;
    align-items: center;
  }
  .footer-about-company {
    width: 100%;
  }
  .footer-about {
    font-size: 20px;
  }
  .content {
    font-size: 14px;
  }
  .footer-logo-text {
    font-size: 25px;
  }
}

@media (max-width: 355px) {
  .footer-bottom > small {
    font-size: 13px;
  }
}
